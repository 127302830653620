@import url("https://fonts.googleapis.com/css2?family=Rajdhani:wght@500;600;700&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  @apply leading-[normal] m-0;
}
*,
::before,
::after {
  border-width: 0;
}

.absolute.\!m-\[0\].right-\[-189px\].bottom-\[-29\.8px\].text-5xl.tracking-\[0\.02em\].font-medium.text-chocolate.z-\[7\].mq450\:text-lgi {
  top: 3rem;
}

header.self-stretch.h-\[113px\].flex.flex-row.items-center.justify-start.py-0.px-\[72px\].box-border.relative.gap-\[37px\].max-w-full.text-left.text-sm.text-gray-100.font-rajdhani {
  position: fixed;
  z-index: 100;
}

.flex.flex-row.items-start.justify-start.relative.mt-\[-990px\].text-13xl.text-light-theme-gray-dark-blue-grey {
  top: 10rem;
}

img.w-full.h-full.absolute.\!m-\[0\].top-\[0px\].right-\[0px\].bottom-\[0px\].left-\[0px\].max-w-full.overflow-hidden.max-h-full {
  scale: 0.9;
}

img.absolute.top-\[0px\].left-\[0px\].w-\[339\.8px\].h-\[112\.3px\] {
  scale: 1.1;
}